/* eslint-disable no-inline-styles/no-inline-styles */
import React, { useState } from 'react';
import { FilePond } from 'react-filepond';
import Select from 'react-select';
import {
    Button,
    Card,
    CardBody,
    Col,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Input,
    Label,
    Row,
    Table,
    UncontrolledDropdown
} from 'reactstrap';
import CustomStepperComponent from '../../../Components/Common/CustomStepperComponent';
import { Tooltip, sortBy } from '../../../Components/Common/Util';
import bluetooth from '../../../assets/images/common/svg/launcher/bluetooth.svg';
import brightness from '../../../assets/images/common/svg/launcher/brightness.svg';
import deviceDetails from '../../../assets/images/common/svg/launcher/deviceDetails.svg';
import FOLDER from '../../../assets/images/common/png/launcher/folder.png';
import screenOrientation from '../../../assets/images/common/svg/launcher/screenOrientation.svg';
import systemSettings from '../../../assets/images/common/svg/launcher/systemSettings.svg';
import wifi from '../../../assets/images/common/svg/launcher/wifi.svg';
import exitQR from '../../../assets/images/common/svg/launcher/exitqr.svg';
import TV_BRANDING_SCHEMA from './TvBrandingSchema';

export const CustomLauncherThemeTemplate = (props) => {
    const [select, setSelect] = useState(false);
    const [selectedFolder, setSelectedFolder] = useState({ name: '', apps: [] });
    const [folderMode, setFolderMode] = useState('add');
    let iconSizeEnum = {
        80: {
            icon: { key: 'small', width: '80', height: '80', widthPercent: '80%', heightPercent: '80%' },
            appName: { key: 'small', width: '15', height: '15' }
        },
        110: {
            icon: { key: 'medium', width: '110', height: '110', widthPercent: '90%', heightPercent: '90%' },
            appName: { key: 'medium', width: '18', height: '18' }
        },
        140: {
            icon: { key: 'large', width: '140', height: '140', widthPercent: '100%', heightPercent: '100%' },
            appName: { key: 'large', width: '22', height: '22' }
        }
    };

    const toggleComponent = (field, index) => {
        const handleToggleChange = (e) => {
            let obj = { ...props.validation.values };
            field?.clearFields?.forEach((val) => {
                if (Object.keys(field?.clearValues)?.includes(val)) obj[val] = field?.clearValues?.[val];
                else obj[val] = '';
            });
            props.validation.setValues(obj);
            props.validation.handleChange(e);
        };
        return (
            <React.Fragment key={index}>
                <Row className="mt-3">
                    <Col sm={7}>
                        <Label className={'mb-0 fw-medium'}>{field.label}</Label>
                        <div className="text-muted wrap-text-word fs-11">{field.helpText}</div>
                    </Col>
                    <Col sm={5}>
                        <div>
                            {props.formType !== 'view' ? (
                                <div className="form-check form-switch form-switch-lg">
                                    <Input
                                        className="form-check-input"
                                        type="checkbox"
                                        role="switch"
                                        name={field.value}
                                        id={field.value}
                                        checked={props.validation?.values[field.value] || false}
                                        onChange={(e) => handleToggleChange(e)}
                                        onBlur={props.validation.handleBlur}
                                    />
                                </div>
                            ) : props.validation?.values?.[field.value] ? (
                                'Yes'
                            ) : (
                                'No'
                            )}
                        </div>
                    </Col>
                </Row>
            </React.Fragment>
        );
    };

    const inputComponent = (field, index) => {
        return (
            <React.Fragment key={index}>
                <Row className="mt-3">
                    <Col sm={12} md={7}>
                        <Label className={'mb-0 fw-medium'}>{field.label}</Label>
                        {field.mandatory && <span className="text-danger">*</span>}
                        <div className="text-muted wrap-text-word fs-11">{field.helpText}</div>
                    </Col>
                    <Col sm={12} md={5}>
                        <div className="input-group">
                            {props.formType !== 'view' ? (
                                <Input
                                    name={field.value}
                                    id={field.value}
                                    className="form-control"
                                    placeholder={`Enter ${field.label}`}
                                    type={field.type}
                                    validate={{ required: { value: true } }}
                                    maxLength={field.maxLength}
                                    onChange={props.validation?.handleChange}
                                    onBlur={props.validation?.handleBlur}
                                    onKeyDown={(e) => props.handleKeyDown(e)}
                                    value={props.validation?.values[field?.value] || ''}
                                    invalid={
                                        props.validation?.touched?.[field?.value] && props.validation?.errors?.[field?.value] ? true : false
                                    }
                                />
                            ) : props.validation?.values[field.value] ? (
                                props.validation?.values[field.value]
                            ) : (
                                '–'
                            )}
                        </div>
                    </Col>
                </Row>
            </React.Fragment>
        );
    };

    const uploadComponent = (field, index) => {
        return (
            <React.Fragment key={index}>
                <Row className="mt-3">
                    <Col sm={12} md={7}>
                        <Label className={'mb-0 fw-medium'}>{field.label}</Label>
                        {field.mandatory && <span className="text-danger">*</span>}
                        <div className="text-muted wrap-text-word fs-11">{field.helpText}</div>
                    </Col>
                    <Col sm={12} md={5}>
                        {props.formType !== 'view' && !props.validation.values?.[field.value] ? (
                            <FilePond
                                name={field.value}
                                minFileSize="1KB"
                                maxFileSize="10MB"
                                maxFiles={1}
                                allowMultiple={true}
                                className="filepond filepond-input-multiple"
                                onaddfile={(_error, fileItems) => props.handleFileUpload(fileItems, field.value)}
                            />
                        ) : props.validation.values?.[field.value] ? (
                            <div className="d-flex align-items-center gap-2">
                                <span
                                    onClick={() => props.handleDownload(props.validation.values?.[field.value])}
                                    className="text-success text-decoration-underline cursor-pointer"
                                >
                                    <img
                                        src={props.validation.values?.[field.value]}
                                        alt={field.value}
                                        className="rounded"
                                        width={40}
                                        height={40}
                                    />
                                </span>
                                {props.formType !== 'view' && (
                                    <div className="cursor-pointer" onClick={() => props.handleEditImage(field.value)}>
                                        <div className="d-flex align-items-center justify-content-center">
                                            <i className="ri-close-line link-primary fs-20" />
                                        </div>
                                    </div>
                                )}
                            </div>
                        ) : (
                            '–'
                        )}
                    </Col>
                </Row>
            </React.Fragment>
        );
    };

    const radioComponent = (field, index) => {
        return (
            <React.Fragment key={index}>
                <Row className="mt-3">
                    <Col sm={12} md={7}>
                        <Label className={'mb-0 fw-medium'}>{field.label}</Label>
                        <div className="text-muted wrap-text-word fs-11">{field.helpText}</div>
                    </Col>
                    <Col sm={12} md={5}>
                        <Row>
                            {field.radios?.map((radio, ind) => (
                                <Col sm="auto" key={ind}>
                                    <div className="form-check form-check-inline" key={ind}>
                                        <Input
                                            className="form-check-input"
                                            type="radio"
                                            id={field.value}
                                            name={field.value}
                                            disabled={props.formType === 'view'}
                                            value={radio.value}
                                            onBlur={props.validation.handleBlur}
                                            onChange={props.validation.handleChange}
                                            checked={props.validation.values[field.value] === radio.value}
                                        />
                                        <Label className="mb-0">{radio.label}</Label>
                                    </div>
                                </Col>
                            ))}
                        </Row>
                    </Col>
                </Row>
            </React.Fragment>
        );
    };

    const colorPicker = (field, index) => {
        return (
            <React.Fragment key={index}>
                <Row className="mt-3">
                    <Col sm={7}>
                        <Label className={'mb-0 fw-medium'}>{field.label}</Label>
                        <div className="text-muted wrap-text-word fs-11">{field.helpText}</div>
                    </Col>
                    <Col sm={5}>
                        <div className={'input-group'}>
                            {props.formType !== 'view' ? (
                                <div className="d-flex align-items-center w-100">
                                    <Input
                                        name={field.value}
                                        id={field.value}
                                        className={'form-control width-30 p-1'}
                                        type={'color'}
                                        validate={{ required: { value: true } }}
                                        onChange={props.validation.handleChange}
                                        onBlur={props.validation.handleBlur}
                                        value={props.validation.values[field.value] || ''}
                                        invalid={
                                            props.validation.touched[field.value] && props.validation.errors[field.value] ? true : false
                                        }
                                    />
                                    <p className="m-0 ms-2">
                                        {props.validation.values[field.value] ? props.validation.values[field.value] : ''}
                                    </p>
                                </div>
                            ) : props.validation.values[field.value] ? (
                                <div className="d-flex">
                                    <div
                                        className="width-20 height-20 me-2 border border-2 rounded"
                                        style={{ backgroundColor: props.validation.values?.[field.value] }}
                                    ></div>
                                    {props.validation.values[field.value]}
                                </div>
                            ) : (
                                '–'
                            )}
                        </div>
                    </Col>
                </Row>
            </React.Fragment>
        );
    };

    const folderComponent = (field, index) => {
        const handleEditFolder = (folder, position) => {
            setSelectedFolder({ folder: folder, position: position });
            setFolderMode('edit');
            props.validation.setValues({ ...props.validation.values, folderName: folder.name, apps: folder.apps });
            setSelect(true);
        };

        const handleDeleteFolder = (position) => {
            let obj = { ...props.validation.values };
            let arr = obj.folders;
            let apps = props.applications;
            apps = apps.concat(arr[position].apps);
            arr.splice(position, 1);
            props.setApplications(apps);
            props.validation.setValues({ ...props.validation.values, folders: arr });
        };

        const handleCancel = () => {
            let apps = JSON.parse(JSON.stringify(props[field.optionsKey] ? props[field.optionsKey] : []));
            let folderApps = JSON.parse(
                JSON.stringify(props.validation.values[field.arrayKey] ? props.validation.values[field.arrayKey] : [])
            );
            let appArr = apps.concat(folderApps);
            props[field.setterKey](appArr);
            props.validation.setValues({ ...props.validation.values, folderName: '', apps: [] });
            setFolderMode('add');
            setSelect(false);
        };

        return (
            <React.Fragment key={index}>
                {!select ? (
                    <Row className="mt-3">
                        <Col sm="auto mb-4">
                            <Card
                                className="card-height-100 width-150 cursor-pointer border border-success rounded-2 d-flex align-items-center justify-content-center"
                                onClick={() => setSelect(true)}
                            >
                                <div>
                                    <img src={FOLDER} alt="" className="m-3" height={30} width={30} />
                                </div>
                                <span className="fw-medium text-success mb-3">Add Folder</span>
                            </Card>
                        </Col>
                        {props.validation.values?.folders?.length > 0
                            ? props.validation.values?.folders?.map((folder, ind) => (
                                  <Col sm="auto mb-4" key={ind}>
                                      <Card className="card-height-100 width-150 border border-success rounded-2 m-0">
                                          <CardBody className="shadow-card position-relative text-center p-5">
                                              <UncontrolledDropdown className="position-absolute top-5 end-5">
                                                  <DropdownToggle
                                                      tag="button"
                                                      type="button"
                                                      className="btn btn-link text-muted p-1 mt-n2 text-decoration-none fs-15"
                                                  >
                                                      <i className="ri-more-2-fill" />
                                                  </DropdownToggle>
                                                  <DropdownMenu className="dropdown-menu-end">
                                                      <DropdownItem onClick={() => handleEditFolder(folder, ind)}>
                                                          <i className="ri-pencil-fill align-bottom me-2 text-muted"></i> Edit
                                                      </DropdownItem>
                                                      <DropdownItem onClick={() => handleDeleteFolder(ind)}>
                                                          <i className="ri-delete-bin-6-line align-bottom me-2 text-muted"></i> Delete
                                                      </DropdownItem>
                                                  </DropdownMenu>
                                              </UncontrolledDropdown>
                                              <Row className="h-100">
                                                  {folder.apps.map((app, inde) => {
                                                      return (
                                                          inde <= 3 &&
                                                          (inde < 3 ? (
                                                              <Col
                                                                  sm={6}
                                                                  key={inde}
                                                                  className="d-flex align-items-center justify-content-center"
                                                              >
                                                                  <div className="bg-white border rounded-2 p-2 w-100 d-flex align-items-center justify-content-center">
                                                                      <img src={app.icondownloadurl} alt="" height={30} />
                                                                  </div>
                                                              </Col>
                                                          ) : (
                                                              <Col
                                                                  sm={6}
                                                                  key={inde}
                                                                  className="d-flex align-items-center justify-content-center"
                                                              >
                                                                  <div className="bg-white border rounded-2 p-2 w-100 d-flex align-items-center justify-content-center">
                                                                      <div className="bg-light rounded-5 text-info p-1">
                                                                          +{folder.apps?.length - 3}
                                                                      </div>
                                                                  </div>
                                                              </Col>
                                                          ))
                                                      );
                                                  })}
                                              </Row>
                                          </CardBody>
                                      </Card>
                                      <div className="d-grid width-150 fw-medium text-center">{folder.name}</div>
                                  </Col>
                              ))
                            : ''}
                    </Row>
                ) : (
                    <div className="border border-1 rounded-2 p-3 mt-3">
                        <div className="d-flex align-items-center justify-content-end">
                            <div
                                className="rounded-5 bg-grey width-20 height-20 d-flex align-items-center justify-content-center link-light cursor-pointer"
                                onClick={handleCancel}
                            >
                                <i className="ri-close-fill" />
                            </div>
                        </div>
                        {field.fields?.map((fieldObj, ind) => handleFields(fieldObj, ind))}
                    </div>
                )}
            </React.Fragment>
        );
    };

    const selectComponent = (field, index) => {
        const handleChange = (e) => {
            let apps = JSON.parse(JSON.stringify(props[field.optionsKey]));
            apps = apps.filter((app) => app.packagename !== e.packagename);
            props[field.setterKey](apps);
            let arr = props.validation.values[field.arrayKey] ? [...props.validation.values[field.arrayKey], e] : [e];
            let obj = { ...props.validation.values, [field.arrayKey]: arr, [field.value]: '' };
            props.validation.setValues(obj);
        };

        const deleteApp = (app) => {
            let apps = JSON.parse(JSON.stringify(props[field.optionsKey]));
            apps.push(app);
            props[field.setterKey](apps);
            let arr = JSON.parse(JSON.stringify(props.validation.values[field.arrayKey]));
            arr = arr.filter((appObj) => appObj.packagename !== app.packagename);
            props.validation.setValues({ ...props.validation.values, [field.arrayKey]: arr });
        };

        const handleCreateFolder = () => {
            let obj = { ...props.validation.values };
            let arr = props.validation.values[field.pushTo]?.length > 0 ? props.validation.values[field.pushTo] : [];
            let newObj = { name: obj.folderName, apps: props.validation.values[field.arrayKey] };
            if (folderMode !== 'edit') arr.push(newObj);
            else arr.splice(selectedFolder.position, 1, newObj);
            obj[field.pushTo] = arr;
            setFolderMode('add');
            obj = { ...obj, folderName: '', apps: [] };
            props.validation.setValues(obj);
            setSelect(false);
        };

        const checkSaveDisable = () => {
            let flag = false;
            field.disable?.forEach((disable) => {
                if (!props.validation.values[disable] || props.validation.values[disable]?.length === 0) {
                    flag = true;
                    return;
                }
            });
            return flag || !props.validation.values?.folderName || props.validation.values.folderName === '';
        };

        return (
            <React.Fragment key={index}>
                <Row className="mt-3">
                    <Col sm={12} md={7}>
                        <Label className={'mb-0 fw-medium'}>{field.label}</Label>
                        <div className="text-muted wrap-text-word fs-11">{field.helpText}</div>
                    </Col>
                    <Col sm={12} md={5}>
                        {props.formType !== 'view' ? (
                            <Select
                                getOptionValue={(option) => (field.optionVal ? option[field.optionVal] : option.value)}
                                getOptionLabel={(option) => (field.optionLabel ? option[field.optionLabel] : option.label)}
                                isMulti={field.isMulti ? true : false}
                                isClearable={field.isMulti ? true : false}
                                id={field.label}
                                name={field.value}
                                options={field.optionsKey ? sortBy(props[field.optionsKey], field.optionLabel) : field.options}
                                placeholder={`Select ${field.label}`}
                                onBlur={() => props.validation.handleBlur({ target: { name: field.value } })}
                                onChange={(selectedOption) => {
                                    if (field?.customCol) handleChange(selectedOption);
                                    else props.validation.handleChange({ target: { name: field.value, value: selectedOption } });
                                }}
                                value={props.validation?.values[field.value] || ''}
                                isSearchable={true}
                                noOptionsMessage={() => 'No data found'}
                            />
                        ) : props.validation?.values?.[field.value]?.length > 0 ? (
                            props.validation?.values?.[field.value]
                                ?.map((v) => v.label)
                                ?.toString()
                                ?.split(',')
                                ?.join(', ')
                        ) : props.validation?.values[field.value]?.label ? (
                            props.validation?.values[field.value]?.label
                        ) : props.validation?.values[field.value] ? (
                            props.validation?.values[field.value]
                        ) : (
                            '–'
                        )}
                    </Col>
                </Row>
                {field.showArray && (
                    <>
                        <Row className="mt-2">
                            {props.validation.values?.[field.arrayKey]?.length > 0 &&
                                props.validation.values[field.arrayKey]?.map((app, ind) => (
                                    <Col sm={3} key={ind}>
                                        <Card className="card-height-100 shadow-card mb-4">
                                            <CardBody className="position-relative text-center">
                                                <div
                                                    className="position-absolute top-0 end-0 cursor-pointer"
                                                    onClick={() => deleteApp(app)}
                                                >
                                                    <i className="ri-close-line fs-14" />
                                                </div>
                                                <img src={app.icondownloadurl} alt="img" height={40} />
                                                <div className="pt-1" id={`app-${ind}`}>
                                                    {app.title?.length > 10 ? app.title?.substr(0, 10) + '...' : app.title}
                                                </div>
                                                {app.title?.length > 10 && Tooltip(`app-${ind}`, app.title)}
                                            </CardBody>
                                        </Card>
                                    </Col>
                                ))}
                        </Row>
                        <Row className="mt-2">
                            <div className="d-flex align-items-center justify-content-end gap-2 w-100">
                                {props.formType !== 'view' && (
                                    <button
                                        type="submit"
                                        className="btn btn-info"
                                        id="add-btn"
                                        disabled={checkSaveDisable()}
                                        onClick={handleCreateFolder}
                                    >
                                        {folderMode === 'edit' ? 'Update' : '+ Add'}
                                    </button>
                                )}
                            </div>
                        </Row>
                    </>
                )}
            </React.Fragment>
        );
    };

    const handleFields = (field, index) => {
        return field.inputType === 'toggle'
            ? toggleComponent(field, index)
            : field.inputType === 'input'
            ? inputComponent(field, index)
            : field.inputType === 'upload'
            ? uploadComponent(field, index)
            : field.inputType === 'radio'
            ? radioComponent(field, index)
            : field.inputType === 'colorPicker'
            ? colorPicker(field, index)
            : field.inputType === 'folder'
            ? folderComponent(field, index)
            : field.inputType === 'select'
            ? selectComponent(field, index)
            : '';
    };

    const handleShow = (conditions) => {
        let flag = true;
        conditions.forEach((condition) => {
            if (!props.validation.values[condition]) {
                flag = false;
                return;
            }
        });
        return flag;
    };

    const stepperData = [
        {
            title: 'Theme Creation',
            icon: 1,
            disabled: false,
            renderTab: (
                <React.Fragment>
                    <div>
                        <Col xs={12} xxs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                            {TV_BRANDING_SCHEMA.map((field, index) => {
                                return field.tab === 'theme' && (field.show ? handleShow(field.show) : true) && handleFields(field, index);
                            })}
                        </Col>
                    </div>
                </React.Fragment>
            )
        },
        {
            title: 'Folder Creation',
            icon: 2,
            disabled: false,
            renderTab: (
                <React.Fragment>
                    <div>
                        {
                            <Col xs={12} xxs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                {TV_BRANDING_SCHEMA.map((field, index) => {
                                    return (
                                        field.tab === 'folder' && (field.show ? handleShow(field.show) : true) && handleFields(field, index)
                                    );
                                })}
                            </Col>
                        }
                    </div>
                </React.Fragment>
            )
        }
    ];

    return (
        <React.Fragment>
            <Row>
                <Col xs={12} xxs={12} sm={12} md={12} lg={6} xl={6} xxl={6} className="border-end border-2">
                    <CustomStepperComponent stepperData={stepperData} />
                </Col>
                <Col xs={12} xxs={12} sm={12} md={12} lg={6} xl={6} xxl={6} className="p-4">
                    <Row>
                        <Col xs={12} xxs={12} sm={12} lg={12} xl={12} xxl={12} className="d-flex align-items-center justify-content-center">
                            <div className={'tvTabPreview mb-3 '}>
                                {!!props.validation.values.enableHeader && (
                                    <div
                                        className={`height-40 w-100 padding-9 ${
                                            props.validation.values.headerTransparent ? 'opacity-50' : ''
                                        }`}
                                        style={{ backgroundColor: props.validation.values.headerBackgroundColor }}
                                    >
                                        <div className="d-flex align-items-center w-100 h-100 gap-2">
                                            {props.validation.values.enableCompanyLogo && (
                                                <>
                                                    {props.validation.values.companyLogo ? (
                                                        <img
                                                            src={props.validation.values.companyLogo}
                                                            alt="companyLogo"
                                                            className="rounded"
                                                            width={36}
                                                            height={36}
                                                        />
                                                    ) : (
                                                        <div className="width-36 height-36 border border-2 rounded" />
                                                    )}
                                                </>
                                            )}
                                            <div style={{ color: props.validation.values.headerTextColor }}>
                                                <div className="d-flex flex-column justify-content-center fs-12">
                                                    {props.validation.values.companyName && (
                                                        <span> {props.validation.values.companyName}</span>
                                                    )}
                                                    {props.validation.values.headerText && (
                                                        <span>{props.validation.values.headerText}</span>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <div
                                    className={`${
                                        props.validation.values.orientation === 'landscape' &&
                                        (props.validation.values.enableHeader && props.validation.values.enableFooter
                                            ? 'height-232'
                                            : props.validation.values.enableHeader || props.validation.values.enableFooter
                                            ? 'height-292'
                                            : 'height-350')
                                    } scroll-element`}
                                >
                                    <Row className="p-3">
                                        {props.validation.values.appsPerRow &&
                                            (props.validation.values.iconOrder === 'ztoa'
                                                ? JSON.parse(JSON.stringify(props.applications))
                                                      ?.splice(0, props.validation.values.appsPerRow.value)
                                                      .reverse()
                                                : props.applications
                                            )?.map(
                                                (app, ind) =>
                                                    ind < props.validation.values.appsPerRow.value &&
                                                    ind <
                                                        props.validation.values.appsPerRow.value -
                                                            (props.validation.values?.folders?.length || 0) && (
                                                        <Col
                                                            sm={12 / Number(props.validation.values?.appsPerRow?.value)}
                                                            key={ind}
                                                            className="mb-4"
                                                        >
                                                            <div className="d-grid justify-content-around place-items-center h-100 p-2">
                                                                <div
                                                                    style={{ backgroundColor: props.validation.values.iconBackgroundColor }}
                                                                    className="br-10 d-grid place-items-center rounded"
                                                                >
                                                                    <img
                                                                        src={app.icondownloadurl}
                                                                        alt="app"
                                                                        width={
                                                                            iconSizeEnum?.[props.validation.values?.iconSize?.toString()]
                                                                                ?.icon?.widthPercent
                                                                        }
                                                                        height={
                                                                            iconSizeEnum?.[props.validation.values?.iconSize?.toString()]
                                                                                ?.icon?.heightPercent
                                                                        }
                                                                    />
                                                                </div>
                                                                {!!props.validation.values.enableAppLabel && (
                                                                    <span
                                                                        className={`mt-1 w-100 float-bottom overflow-hidden white-space-nowrap text-overflow-ellipsis text-white ${
                                                                            props.validation.values.enableLabelShadow ? 'textshadow' : ''
                                                                        }`}
                                                                    >
                                                                        {app.name}
                                                                    </span>
                                                                )}
                                                            </div>
                                                        </Col>
                                                    )
                                            )}
                                        {props.validation.values?.folders?.length > 0
                                            ? props.validation.values?.folders?.map((folder, ind) => (
                                                  <Col
                                                      sm={12 / Number(props.validation.values?.appsPerRow?.value)}
                                                      key={ind}
                                                      className={`${props.validation.values.enableAppLabel && 'mb-4'}`}
                                                  >
                                                      <Card
                                                          className={`card-height-100 height-80 width-80 border border-grey rounded-2 p-2 ${
                                                              props.validation.values.enableAppLabel && 'mb-0'
                                                          }`}
                                                      >
                                                          <CardBody className="text-center p-2">
                                                              <Row className="h-50">
                                                                  {folder.apps.map((app, inde) => {
                                                                      return (
                                                                          inde <= 3 &&
                                                                          (inde < 3 ? (
                                                                              <Col
                                                                                  sm={6}
                                                                                  key={inde}
                                                                                  className="d-flex align-items-center justify-content-center p-0"
                                                                              >
                                                                                  <div className="bg-light border rounded-2 w-75 d-flex align-items-center justify-content-center">
                                                                                      <img src={app.icondownloadurl} alt="" height={25} />
                                                                                  </div>
                                                                              </Col>
                                                                          ) : (
                                                                              <Col
                                                                                  sm={6}
                                                                                  key={inde}
                                                                                  className="d-flex align-items-center justify-content-center p-0"
                                                                              >
                                                                                  <div className="bg-light border rounded-2 w-75 d-flex align-items-center justify-content-center">
                                                                                      <div className="bg-light rounded-5 text-info p-1">
                                                                                          +{folder.apps?.length - 3}
                                                                                      </div>
                                                                                  </div>
                                                                              </Col>
                                                                          ))
                                                                      );
                                                                  })}
                                                              </Row>
                                                          </CardBody>
                                                      </Card>
                                                      {!!props.validation.values.enableAppLabel && (
                                                          <div
                                                              className={`width-80 float-bottom overflow-hidden white-space-nowrap text-overflow-ellipsis text-white text-center ${
                                                                  props.validation.values.enableLabelShadow ? 'textshadow' : ''
                                                              }`}
                                                          >
                                                              {folder.name}
                                                          </div>
                                                      )}
                                                  </Col>
                                              ))
                                            : ''}
                                    </Row>
                                </div>
                                {!!props.validation.values.enableFooter && (
                                    <div
                                        className={`position-absolute bottom-0 height-40 w-100 d-flex align-items-center ${
                                            props.validation.values.footerTransparent ? 'opacity-50' : ''
                                        }`}
                                        style={{
                                            color: props.validation.values.footerTextColor,
                                            backgroundColor: props.validation.values.footerBackgroundColor
                                        }}
                                    >
                                        <span className="ps-2 overflow-hidden white-space-nowrap text-overflow-ellipsis">
                                            {props.validation.values.footerText}
                                        </span>
                                    </div>
                                )}
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export const SettingsTemplate = (props) => {
    const images = {
        wifi: wifi,
        bluetooth: bluetooth,
        screenOrientation: screenOrientation,
        systemSettings: systemSettings,
        details: deviceDetails,
        brightness: brightness,
        exitqr: exitQR
    };
    return (
        <>
            <div className="d-flex align-items-center justify-content-end gap-2 margin-bottom-10 margin-top-15">
                {/* <div>
					<Button
						type="button"
						id="close_timer"
						color="success"
						className="py-1"
						disabled={formType === 'view'}
						onClick={() => handleTimer()}
					>
						<span className="d-flex align-items-center">
							<i className="ri-time-line fs-16 align-bottom me-1"></i> Closer Time
						</span>
					</Button>
					{Tooltip('close_timer', validation.values.close_time ? validation.values.close_time : '-:-')}
				</div> */}
                <div>
                    <Button
                        type="button"
                        id="default_password"
                        disabled={props.formType === 'view'}
                        color="primary"
                        className="py-1"
                        onClick={() => props.handlePassword('default_password', 'setting_default_password_changing')}
                    >
                        <span className="d-flex align-items-center">
                            <i className="ri-lock-2-line fs-16 align-bottom me-1"></i> Default
                        </span>
                    </Button>
                    {Tooltip('default_password', props.validation.values.setting_default_password)}
                </div>
                <div>
                    <Button
                        type="button"
                        id="custom_password"
                        disabled={props.formType === 'view'}
                        color="green-graph2"
                        className="py-1"
                        onClick={() => props.handlePassword('custom_password', 'setting_custom_password_changing')}
                    >
                        <span className="d-flex align-items-center">
                            <i className="ri-lock-2-line fs-16 align-bottom me-1"></i> Custom
                        </span>
                    </Button>
                    {Tooltip('custom_password', props.validation.values.setting_custom_password)}
                </div>
            </div>
            <Table className="table-nowrap table-border table-centered align-middle table table-hover">
                <thead className="bg-none fw-normal">
                    <tr className="fs-14">
                        <td></td>
                        <td className="fw-medium">Show/Hide</td>
                        <td className="fw-medium"> Timer</td>
                        <td className="fw-medium">Timer (in seconds)</td>
                        <td className="fw-medium">Require Password</td>
                        <td className="fw-medium">Password Type</td>
                        <td className="fw-medium">Password</td>
                    </tr>
                </thead>
                <tbody>
                    {props.settings.map((setting, ind) => (
                        <tr key={ind} className={`${setting.bgClass ? setting.bgClass : ind % 2 === 0 ? 'bg-mutes' : 'bg-none'}`}>
                            <td>
                                <div className="d-flex align-items-center padding-left-20 fs-14">
                                    {!setting.hideAll && (
                                        <img src={images[setting.image]} alt={setting.image} className="me-2" width={30} height={30} />
                                    )}
                                    {setting.heading}
                                </div>
                            </td>
                            <td>
                                <div className="h-100 w-100">
                                    <div className="d-flex align-items-center">
                                        {!setting.hideAll &&
                                            (props.formType === 'view' ? (
                                                !!props.featureEnabled[setting.value] ? (
                                                    'Visible'
                                                ) : (
                                                    'Hidden'
                                                )
                                            ) : (
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input border-primary"
                                                    checked={props.featureEnabled[setting.value]}
                                                    disabled={setting.disableShow}
                                                    value={props.featureEnabled[setting.value]}
                                                    onChange={(e) =>
                                                        props.handleEnableChange(e.target.checked, setting.value, setting, 'value')
                                                    }
                                                />
                                            ))}
                                    </div>
                                </div>
                            </td>
                            <td>
                                <span>
                                    <div className="ps-1 form-switch form-switch-md">
                                        {!setting.hideAll &&
                                            (props.formType === 'view' ? (
                                                !!props.validation.values?.[setting.req_timer] ? (
                                                    'Timer required'
                                                ) : !props.validation.values?.[setting.timer] ? (
                                                    '—'
                                                ) : (
                                                    'Timer not required'
                                                )
                                            ) : (
                                                <div className="form-check form-switch form-switch-md ms-2">
                                                    <Input
                                                        className={
                                                            'form-check-input height-15 width-33 ' +
                                                            (!!props.validation.values?.[setting.timer] &&
                                                            !props.validation.values?.[setting.req_timer]
                                                                ? ' border-primary'
                                                                : '')
                                                        }
                                                        type="checkbox"
                                                        role="switch"
                                                        name={setting.req_timer}
                                                        id={setting.req_timer}
                                                        checked={!!props.validation.values?.[setting.req_timer]}
                                                        onChange={props.validation.handleChange}
                                                    />
                                                </div>
                                            ))}
                                    </div>
                                </span>
                            </td>
                            <td className="min-width-50 max-width-50">
                                <div className="d-flex align-items-center h-100">
                                    {!setting.hideAll &&
                                        (props.validation.values[setting.req_timer] ? (
                                            props.formType === 'view' ? (
                                                props.validation.values[setting.timer]
                                            ) : props.validation.values[setting.req_timer] ? (
                                                <Input
                                                    name={setting.timer}
                                                    id={setting.timer}
                                                    className="form-control w-50"
                                                    onChange={(e) => props.handleIndividualTimerChange(e, setting.timer)}
                                                    value={props.validation.values[setting.timer]}
                                                />
                                            ) : (
                                                props.validation.values[setting.timer]
                                            )
                                        ) : (
                                            '—'
                                        ))}
                                </div>
                            </td>
                            <td>
                                <span>
                                    <div className="ps-1 form-switch form-switch-md">
                                        {!setting.hideAll &&
                                            (props.formType === 'view' ? (
                                                !!props.featureEnabled[setting.password_value] ? (
                                                    'Password required'
                                                ) : !props.featureEnabled[setting.value] ? (
                                                    '—'
                                                ) : (
                                                    'Password not required'
                                                )
                                            ) : (
                                                <div className="form-check form-switch form-switch-md ms-2">
                                                    <Input
                                                        className={
                                                            'form-check-input height-15 width-33 ' +
                                                            (!!props.featureEnabled[setting.value] &&
                                                            !props.featureEnabled[setting.password_value]
                                                                ? ' border-primary'
                                                                : '')
                                                        }
                                                        type="checkbox"
                                                        role="switch"
                                                        name={setting.password_value}
                                                        disabled={!props.featureEnabled[setting.value]}
                                                        id={setting.password_value}
                                                        checked={!!props.featureEnabled[setting.password_value]}
                                                        onChange={(e) =>
                                                            props.handleEnableChange(
                                                                e.target.checked,
                                                                setting.password_value,
                                                                setting,
                                                                'value'
                                                            )
                                                        }
                                                    />
                                                </div>
                                            ))}
                                    </div>
                                </span>
                            </td>
                            <td className="min-width-150 max-width-150">
                                <div className="d-flex align-items-center">
                                    {!setting.hideAll &&
                                        (!!props.featureEnabled[setting.password_value] ? (
                                            props.formType === 'view' ? (
                                                props.validation.values[setting.password_type] === 'true' ? (
                                                    'Default'
                                                ) : (
                                                    'Custom'
                                                )
                                            ) : (
                                                <div className="d-flex align-items-center">
                                                    <div className="form-check form-check-inline">
                                                        <Input
                                                            type="radio"
                                                            value={'true'}
                                                            id={setting.password_type}
                                                            name={setting.password_type}
                                                            className="form-check-input"
                                                            onChange={(e) => {
                                                                props.validation.handleChange(e);
                                                                props.handlePasswordTypeChange(e, setting.password, 'default');
                                                            }}
                                                            checked={props.validation.values[setting.password_type] === 'true'}
                                                        />
                                                        <Label className="ms-1 form-check-label">{'Default'}</Label>
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                        <Input
                                                            type="radio"
                                                            value={'false'}
                                                            id={setting.password_type}
                                                            name={setting.password_type}
                                                            className="form-check-input"
                                                            onChange={(e) => {
                                                                props.validation.handleChange(e);
                                                                props.handlePasswordTypeChange(e, setting.password, 'custom');
                                                            }}
                                                            checked={props.validation.values[setting.password_type] === 'false'}
                                                        />
                                                        <Label className="ms-1 form-check-label">{'Custom'}</Label>
                                                    </div>
                                                </div>
                                            )
                                        ) : (
                                            '—'
                                        ))}
                                </div>
                            </td>
                            <td className="min-width-150 max-width-150">
                                <div className="d-flex align-items-center h-100">
                                    {!setting.hideAll &&
                                        (props.featureEnabled[setting.password_value] ? (
                                            props.formType === 'view' ? (
                                                props.passwords[setting.password]
                                            ) : props.validation.values[setting.password_type] === 'false' ? (
                                                <Input
                                                    className="form-control py-1"
                                                    onChange={(e) => props.handlePasswordChange(e, setting.password)}
                                                    value={props.passwords[setting.password]}
                                                />
                                            ) : (
                                                props.passwords[setting.password]
                                            )
                                        ) : (
                                            '—'
                                        ))}
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </>
    );
};
